body,
html {
  width: 100%;
  height: 100%;
}

@font-face {
  font-display: swap;
}

*,
:after,
:before {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.65);
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5715;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #484848;
  font-weight: 500;
  margin-bottom: 0.5em;
  margin-top: 0;
}

p {
  margin-bottom: 1em;
  margin-top: 0;
  white-space: pre-line;
}

body {
  background-color: #f2f2f2;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
}

hr {
  width: 100%;
}

.ghostButton {
  background: transparent;
  border: 0;
}

.bookingHeader {
  color: #484848;
  font-weight: 700;
  font-size: 28px;
  line-height: 30px;
  text-align: left;
  margin-top: 1.5em;
}

.bookingHeaderTextStyle {
  color: #484848 !important;
  font-weight: 700 !important;
}

.bottomBorderGrey {
  border-bottom: 1px solid rgb(228, 228, 228);
}

.bookingModalDiv {
  text-align: left;
}

.closeIconSvg {
  cursor: pointer;
  fill: #454f63 !important;
  height: 12.6333px !important;
  stroke: #454f63;
  width: 12.6333px !important;
}

.displayNone {
  display: none;
}

.displayBlock {
  display: block;
}

.textCenter {
  text-align: center !important;
}

.FlexCol {
  display: flex;
  flex-direction: column;
}

.FlexColJustCenter80vh {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.FlexColJustCenter90vh {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.FlexColJustCenterBookingHeader {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.FlexColJustCenterHealthDeclarationPageHeader {
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.FlexColJustCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flexJustCenter {
  display: flex;
  justify-content: center;
}

.flexStartSelf {
  display: flex;
  align-self: flex-start;
}

.flexSpaceBetween {
  display: flex;
  justify-content: space-between;
}

.HealthDeclarationCheckBox {
  background: white;
  border-color: #444;
  border-radius: 3px;
  border: 2px solid;
  box-sizing: border-box;
  cursor: pointer;
  fill: #444;
  flex-shrink: 0;
  height: 25px;
  margin: 5px;
  padding: 3px 2px 2px;
  width: 25px;
}

.HealthDeclarationCheckBoxChecked {
  border-color: #000 !important;
}

.flexRowCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flexColStart {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.flexWidth100 {
  display: flex;
  width: 100%;
}

.HealthDeclarationButton {
  background-color: rgb(24, 144, 255);
  border-color: rgb(24, 144, 255);
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border: none;
  box-shadow: 0px 2px 0 #ddd;
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-size: 14px;
  height: 32px;
  line-height: 16px;
  min-height: 45px;
  outline: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

.fixedCenter {
  left: 50%;
  margin-left: -37.5%;
  position: fixed;
  top: 50%;
  width: 75%;
}

.HealthDeclarationNextButton {
  height: 32px;
  line-height: 16px;
  margin-bottom: 50px;
  margin-top: 25px;
  min-height: 45px;
  width: 300px;
}

.HealthDeclarationNextButton:disabled {
  background-color: #cccccc;
  border: 1px solid #999999;
  box-shadow: none;
  color: #666666;
  cursor: default;
}

.HealthDeclarationNextButtonDiv {
  z-index: 100;
  width: 100%;
  background-color: transparent;
  position: static;
  right: 0;
}

.HealthDeclarationTwoAlternativeButton {
  margin-bottom: 80px;
  width: 150px;
}

.HealthDeclarationTwoAlternativeButtonDiv {
  bottom: 50px;
  left: 50%;
  position: fixed;
  transform: translate(-50%, 0);
  width: 100vh;
}

.HealthDeclarationMedicineCheckboxColumn {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 1em;
}

.HealthDeclarationTextArea {
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.21);
  display: flex;
  font-family: Arial, sans-serif;
  height: 61px;
  margin: 0px;
  transition: all 0.3s ease 0s;
  width: 100%;
}

.HealthDeclarationPreviewH2 {
  text-align: left;
  margin-top: 30px;
}

.HealthDeclarationSmileyDiv {
  background: #f6f9fc;
  border-color: transparent;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  box-shadow: rgb(0 0 0 / 15%) 0px 1.95px 0px;
  cursor: pointer;
  padding: 15px;
  width: 100%;
}

.HealthDeclarationSmileySVGSize {
  height: 50px;
}

.HealthDeclarationSmileyLabel {
  margin-left: 20px;
  color: #484848;
  font-size: 24px;
  line-height: 26px;
  font-weight: 600;
}

.innerHeaderContainer {
  display: flex;
  margin: 0 auto auto 0;
}

.maxWidth1280px {
  max-width: 1280px !important;
}

.MobileSearchModalHeader {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  height: 56px;
  justify-content: center;
  line-height: 16px;
  margin: 0px 20px 0px 0px !important;
  text-align: center;
  width: 100%;
}

.MobileModalSearchBar {
  width: 100% !important;
  margin: 0px !important;
  padding: 0 20px;
}

.NoPadding {
  padding: 0px !important;
}

.StartPageBackground {
  background-image: url("../assets/svg/start-page-background.svg");
  background-position: 0% 30%;
  background-repeat: no-repeat;
  background-size: cover;
}

.SubLabel {
  font-size: 12px;
  line-height: 14px;
  margin-top: 5px;
}

.layout {
  background-color: white;
  display: flex;
  flex-direction: column;
  flex: auto;
  min-height: 100vh;
  position: relative;
}

.LeftArrowSVGMobileSeachModal {
  cursor: pointer;
  left: 0;
  margin: 18px 0px 0px 25px;
  padding: 0px !important;
  position: absolute;
  top: 0;
}

.link {
  text-decoration: none !important;
  color: #484848;
}

.link:hover {
  color: rgb(0, 122, 255) !important;
}

.positionFixed {
  position: fixed !important;
}

.st0 {
  fill: white;
  stroke: rgb(24, 144, 255);
  stroke-width: 4;
  stroke-miterlimit: 10;
}

.st1 {
  fill: rgb(24, 144, 255);
  stroke: rgb(24, 144, 255);
  stroke-miterlimit: 10;
}

.greenSt0 {
  stroke: #2eb67d !important;
}
.greenSt1 {
  fill: #2eb67d !important;
  stroke: #2eb67d !important;
}

.yellowSt0 {
  stroke: #e0d200 !important;
}
.yellowSt1 {
  fill: #e0d200 !important;
  stroke: #e0d200 !important;
}

.overflowHidden {
  overflow: hidden;
}

.orangeSt0 {
  stroke: #f8a915 !important;
}
.orangeSt1 {
  fill: #f8a915 !important;
  stroke: #f8a915 !important;
}

.transitionStep {
  text-align: center;
  transition: 150ms opacity, 150ms transform;
  opacity: 1;
  overflow-x: hidden;
  overflow-y: hidden;
}

.transitionStep.next {
  transform: translateX(500%);
  opacity: 0;
  position: absolute;
}

.transitionStep.previous {
  transform: translateX(-500%);
  opacity: 0;
  position: absolute;
}

@media (max-width: 993px) {
  .HealthDeclarationTwoAlternativeButtonDiv {
    position: fixed;
    bottom: 0px;
    width: 100vh;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .FlexColJustCenter80vh {
    height: 80vh;
  }

  .FlexColJustCenter90vh {
    height: 90vh;
  }

  .HideIfMobile {
    display: none !important;
  }

  .StartPageBackground {
    background-position: 0% 0%;
    background-size: contain;
  }
  .SearchSVG {
    max-width: 16px !important;
    max-height: 16px !important;
  }
  .LocationSVG {
    max-width: 16px !important;
    max-height: 16px !important;
    width: 16px !important;
    height: 16px !important;
  }
  .SVGContainer {
    flex-shrink: 0 !important;
    height: 42px !important;
    margin: 0 !important;
    padding: 13px !important;
    width: 42px !important;
  }
  .Logo {
    height: 64px !important;
    width: 128px !important;
  }
  .FlexColMobile {
    display: flex;
    flex-direction: column;
  }

  .StickyMobileHeader {
    position: sticky;
    top: 0;
    z-index: 100;
  }
}

@media (min-width: 250px) {
  .StartPageMainDiv {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .healthDeclarationLayout {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 375px) {
  .StartPageMainDiv {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .healthDeclarationLayout {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (min-width: 744px) {
  .StartPageMainDiv {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .healthDeclarationLayout {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 950px) {
  .StartPageMainDiv {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .HideIfDesktop {
    display: none !important;
  }
  .healthDeclarationLayout {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 1128px) {
  .StartPageMainDiv {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .healthDeclarationLayout {
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media (min-width: 1440px) {
  .StartPageMainDiv {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
}

@media (min-width: 250px) {
  .dateSelectorHeaderDiv {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 375px) {
  .dateSelectorHeaderDiv {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (min-width: 744px) {
  .dateSelectorHeaderDiv {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 950px) {
  .dateSelectorHeaderDiv {
    padding-left: 40px;
    padding-right: 40px;
  }
  .width33em {
    width: 33em;
  }
}

@media (min-width: 1128px) {
  .dateSelectorHeaderDiv {
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media (min-width: 1440px) {
  .dateSelectorHeaderDiv {
    padding: 0 7% !important;
  }
}

.FakeSearchHeader {
  text-align: left !important;
}

.LeftArrowSVG {
  fill: #454f63 !important;
  max-height: 20px;
  max-width: 20px;
  stroke: #454f63;
}

.LocationSVG {
  stroke: #454f63;
  max-width: 12px;
  max-height: 12px;
}

.MobileSearchModal {
  margin: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  display: flex;
  flex-direction: column;
}

.SVG16px {
  height: 16px !important;
  max-height: 16px;
  max-width: 16px;
  stroke: #454f63;
  width: 16px !important;
}

.SearchButtonSVG {
  margin: 0 !important;
}
.SVGContainer {
  flex-shrink: 0;
  height: 42px;
  margin: 0;
  padding: 12px;
  width: 42px;
}

.SearchSVG {
  max-height: 12px;
  max-width: 12px;
  stroke: #454f63;
}

.GreyFill {
  fill: #00000036;
}

.Logo {
  height: 80px;
  width: 175px;
}

.noMargin {
  margin: 0px !important;
}

.MobileHeaderSearchModalButton {
  margin: 0px 0px 5px 0px !important;
}

.transparentSVG {
  fill: transparent !important;
  stroke: transparent !important;
}
